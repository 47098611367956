import React, { useEffect, useState } from 'react';
import nexo from '../../nexoClient';
import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Card,
  Box,
  Button,
  Select,
  Label,
  Checkbox,
  useToast,
  Text,
  Title,
  Table,
  IconButton,
  Icon,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import axiosInstance from '../../axios';
import { CogIcon, ExternalLinkIcon, RocketIcon } from '@nimbus-ds/icons';
import { useWindowWidth } from '../../hooks';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import { useHistory } from 'react-router-dom';

const LogsPage: React.FC = () => {
  const { push } = useHistory();
  const [orders, setOrdersLogs] = useState([]);
  const [clients, setClientsLogs] = useState([]);
  const [products, setProductsLogs] = useState([]);
  const [isviewProducts, setViewProducts] = useState(true);
  const [isviewOrders, setViewOrders] = useState(false);
  const [isviewClients, setViewClients] = useState(false);
  const windowWidth = useWindowWidth();
  const breakpointMd = tokens.breakpoint.md.value.replace('px', '');

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  useEffect(() => {
    //getLogs();
  }, []);

  const goToSetup = () => push('/setup');

  interface Product {
    id: number;
    sku: string;
    type: string;
    tn_id: string | null;
    marketplace_tn_id: string | null;
    message: string | null;
    created_at: string | null;
  }

  interface Client {
    id: number;
    customer_identification: string;
    type: string;
    customer_name: string;
    tn_id: string | null;
    message: string | null;
    created_at: string | null;
  }

  interface Order {
    id: number;
    tn_order_number: number;
    type: string;
    customer_identification: string;
    message: string | null;
    created_at: string | null;
  }

  /*
  const getLogs = async () => {
    console.log('get logs call');
    await axiosInstance
      .get('/logs')
      .then((logs) => {
        if (logs.data.p_logs) {
          setProductsLogs(logs.data.p_logs);
        }
        if (logs.data.c_logs) {
          setClientsLogs(logs.data.c_logs);
        }
        if (logs.data.o_logs) {
          setOrdersLogs(logs.data.o_logs);
        }
        return true;
      })
      .catch((e) => {
        console.error(e);
        if (e.response?.data?.error_code == 'APP_VALIDATION_PENDING') {
          goToSetup();
        }
      });

    return;
  };
  */

  const viewProducts = async () => {
    console.log('view products ');
    setViewOrders(false);
    setViewClients(false);
    setViewProducts(true);
  };

  const viewOrders = async () => {
    console.log('view orders ');
    setViewOrders(true);
    setViewClients(false);
    setViewProducts(false);
  };

  const viewClients = async () => {
    console.log('view clients ');
    setViewOrders(false);
    setViewClients(true);
    setViewProducts(false);
  };

  const actions =
    windowWidth !== null && windowWidth <= breakpointMd ? (
      <IconButton
        backgroundColor="transparent"
        borderColor="transparent"
        onClick={viewProducts}
        source={<CogIcon />}
        size="2rem"
      />
    ) : (
      <Box display="flex" gap="4">
        <Box paddingRight="10">
          <Button appearance="primary">Refrescar</Button>
        </Box>
        <Button appearance="primary" onClick={viewProducts}>
          <Icon source={<CogIcon />} color="currentColor" />
          Productos
        </Button>
        <Button appearance="primary" onClick={viewOrders}>
          <Icon source={<CogIcon />} color="currentColor" />
          Ordenes
        </Button>
        <Button appearance="primary" onClick={viewClients}>
          <Icon source={<CogIcon />} color="currentColor" />
          Clientes
        </Button>
      </Box>
    );

  return (
    <Page maxWidth="1200px">
      <Page.Header title="Registros" buttonStack={actions} />
      <Page.Body>
        <div></div>
      </Page.Body>
    </Page>
  );
};

export default LogsPage;
