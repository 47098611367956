import React, { useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Card,
  Icon,
  Spinner,
  List,
  useToast,
  Input,
  Text,
  Label,
  Select,
  Title,
  Textarea,
  Radio,
  Checkbox,
} from '@nimbus-ds/components';
import { CogIcon, LockOpenIcon, UserIcon } from '@nimbus-ds/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useHistory } from 'react-router-dom';
import { Table } from '@nimbus-ds/components';

const headers = ['Id', 'Tienda', 'Nombre', 'Email', 'Razón Social', 'Acciones'];

import axiosInstance from '../../axios';

const PageDashboard: React.FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isVerifing, setIsVerifing] = useState(true);
  const [isVinculate, setIsVinculate] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isUninstalled, setIsUninstalled] = useState(false);
  const [showMessageVinculateAccount, setShowMessageVinculateAccount] =
    useState(false);
  const [errorValidateStore, setErrorValidateStore] = useState(false);
  const [error, setError] = useState(false);
  const [checkBox1, setCheckbox1] = useState(false);
  const [checkBox2, setCheckbox2] = useState(false);
  const [form, setFormData] = useState({
    nombre_tienda: '',
    descripcion: '',
    origen_producto: '',
    tipo_oferta: '',
    registro_marca: '',
    elabora_producto: '',
    nombre: '',
    telefono: '',
    email: '',
    url_tienda: '',
  });

  interface Seller {
    store_id: string;
    name: string;
    email: string;
    razon_social: string;
    url: string;
    status: string;
  }

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  useEffect(() => {
    checkStatusAccount();
    //getListOfPendingSellers();
    //getListOfSellers();
  }, []);

  const handleGoToDashboard = async () => {
    push('/');
  };

  const handleGoToConfig = async () => {
    push('/config');
  };

  const getDashboard = async () => {
    try {
      const r = await axiosInstance.get('/dashboard');
      console.log(r);
    } catch (e) {
      console.error(e);
    }
    return;
  };
  getDashboard();

  /*
  const handleSellerStatusChange = (e: any) => {
    console.log(e.target.value, ' - ', e.target.key, ' - ', e.target.label);
    console.log(e.target.id);
    console.log(e.key);
  };
  */

  const handleChange = (e: any) => {
    setFormData({
      ...form,
      [e.target.name]: e.target.value,
    });
    console.log('new form data ', form);
  };

  const checkStatusAccount = async () => {
    console.log('verificando vinculacion de cuenta...');
    try {
      const response = await axiosInstance.get('/check_status_account');
      console.log(response);
      if (response.data.status == true) {
        console.log('CUENTA YA VINCULADA');
        if (response.data.is_vinculate) {
          setIsVinculate(true);
        }
        if (response.data.is_approved) {
          setIsApproved(true);
        }
        if (response.data.is_rejected) {
          setIsRejected(true);
        }
        if (response.data.is_uninstalled) {
          setIsUninstalled(true);
        }
        setIsVerifing(false);
      }
    } catch (e) {
      console.error(e);
      setIsVerifing(false);
      setIsVinculate(false);
      setErrorValidateStore(true);
    }
    return;
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setError(true);
      return false;
    } else {
      setError(false);
      return true;
    }
  };

  const handleChangeCheckbox1 = (e: any) => {
    console.log(e.target.value);
    console.log(e.target.checked);
    setCheckbox1(e.target.checked);
  };

  const handleChangeCheckbox2 = (e: any) => {
    console.log(e.target.value);
    console.log(e.target.checked);
    setCheckbox2(e.target.checked);
  };

  const validateForm = () => {
    if (
      !form.nombre_tienda ||
      !form.origen_producto ||
      !form.tipo_oferta ||
      !form.registro_marca ||
      !form.nombre ||
      !form.email ||
      !form.telefono ||
      !form.url_tienda
    ) {
      return false;
    }
    if (validateEmail(form.email) == true) return true;

    return false;
  };

  const handleForm = async () => {
    const isFormValid = validateForm();

    if (checkBox1 == false || checkBox2 == false) {
      addToast({
        id: 'policy-checkbox',
        type: 'danger',
        text: 'Debe aceptar los terminos y condiciones',
        duration: 4000,
      });
      return;
    }
    setLoading(true);

    if (isFormValid) {
      setShowMessageVinculateAccount(true);
      try {
        const response = await axiosInstance.post('/seller_register', {
          nombre_tienda: form.nombre_tienda,
          descripcion: form.descripcion,
          origen_producto: form.origen_producto,
          tipo_oferta: form.tipo_oferta,
          registro_marca: form.registro_marca,
          elabora_producto: form.elabora_producto,
          nombre: form.nombre,
          telefono: form.telefono,
          email: form.email,
          url_tienda: form.url_tienda,
        });
        if (response.data.status == true) {
          addToast({
            id: 'login-toast',
            type: 'success',
            text: 'Vinculación de cuenta exitosa!',
            duration: 4000,
          });
          setIsVerifing(false);
          setIsVinculate(true);
          setShowMessageVinculateAccount(false);
        } else {
          setShowMessageVinculateAccount(false);
          addToast({
            id: 'login-toast',
            type: 'danger',
            text: 'Error Vinculando cuenta. Verifique sus datos.',
            duration: 4000,
          });
        }
      } catch (error) {
        console.log(error);
        setError(true);
        setShowMessageVinculateAccount(false);
      }
    } else {
      addToast({
        id: 'form-incomplete-toast',
        type: 'danger',
        text: 'Verifique sus datos.',
        duration: 4000,
      });
    }
    setLoading(false);
    return;
  };

  if (isVerifing)
    return (
      <div>
        <label>Verificando vinculación...</label>
      </div>
    );

  if (errorValidateStore)
    return (
      <div>
        <label>Error al verificar estado de la cuenta.</label>
      </div>
    );

  return (
    <Page maxWidth="700px" marginTop="18">
      {!isVerifing && !isVinculate ? (
        <>
          <Page.Header alignItems="center" title="Formulario de alta" />
          <Card>
            <Card.Header title="" />
            <Card.Body>
              <Box display="flex" flexDirection="column" gap="4" padding="2">
                <Text as="p">
                  <strong>NEGOCIO</strong>
                </Text>
                <FormField label="Nombre de tu tienda *">
                  <Input
                    name="nombre_tienda"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Descripción">
                  <Textarea
                    id="textarea"
                    name="descripcion"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Origen del Producto *">
                  <Select
                    appearance="neutral"
                    id="origen_producto"
                    name="origen_producto"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  >
                    <Select.Option
                      disabled
                      label="Seleccionar origen"
                      selected
                      value=""
                    />
                    <Select.Option
                      label="Todos son producidos en México"
                      value="Todos son producidos en México"
                    />
                    <Select.Option
                      label="Algunos son producidos en México"
                      value="Algunos son producidos en México"
                    />
                    <Select.Option
                      label="Algunos son producidos en el extranjero y vendido en México"
                      value="Algunos son producidos en el extranjero y vendido en México"
                    />
                    <Select.Option
                      label="Todos son producidos en el extranjero y vendido en México"
                      value="Todos son producidos en el extranjero y vendido en México"
                    />
                  </Select>
                </FormField>
                <FormField label="Tipo de Oferta *">
                  <Select
                    appearance="neutral"
                    id="tipo_oferta"
                    name="tipo_oferta"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  >
                    <Select.Option
                      disabled
                      label="Seleccionar tipo"
                      selected
                      value=""
                    />
                    <Select.Option
                      label="Vendo productos"
                      value="Vendo productos"
                    />
                    <Select.Option
                      label="Vendo servicios"
                      value="Vendo servicios"
                    />
                  </Select>
                </FormField>
                <FormField label="¿Cuentas con registro de marca en el IMPI? *">
                  <Select
                    appearance="neutral"
                    id="registro_marca"
                    name="registro_marca"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  >
                    <Select.Option
                      disabled
                      label="Seleccionar registro"
                      selected
                      value=""
                    />
                    <Select.Option label="Si" value="Si" />
                    <Select.Option
                      label="Ya está en proceso de registro"
                      value="Ya está en proceso de registro"
                    />
                    <Select.Option
                      label="No, pero si me interesa"
                      value="No, pero si me interesa"
                    />
                    <Select.Option label="No" value="No" />
                  </Select>
                </FormField>
                <FormField label="¿En qué estado de México se elabora o fabrica tu producto?">
                  <Input
                    name="elabora_producto"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <Text as="p">
                  <strong>INFORMACIÓN PERSONAL</strong>
                </Text>
                <FormField label="Nombre de contacto *">
                  <Input
                    name="nombre"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Teléfono de contacto *">
                  <Input
                    name="telefono"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Correo electrónico *">
                  <Input
                    name="email"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <FormField label="Link de tienda *">
                  <Input
                    name="url_tienda"
                    type="text"
                    placeholder=""
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormField>
                <Text as="p">
                  <strong>TÉRMINOS Y CONDICIONES</strong>
                </Text>
                <Box display="inline-flex" gap="2"></Box>
                <Box display="inline-flex" gap="2">
                  <Checkbox
                    name="terminos-1"
                    label=""
                    checked={checkBox1}
                    onChange={(event) => {
                      handleChangeCheckbox1(event);
                    }}
                  />
                  <Text as="p">
                    {' '}
                    Acepto haber leído, comprendido y autorizado plenamente la{' '}
                    <a
                      href="https://mxlimited.mitiendanube.com/aviso-de-privacidad/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Declaración de privacidad y confidencialidad
                    </a>
                    .
                  </Text>
                </Box>
                <Box display="inline-flex" gap="2">
                  <Checkbox
                    name="terminos-2"
                    label=""
                    checked={checkBox2}
                    onChange={(event) => {
                      handleChangeCheckbox2(event);
                    }}
                  />
                  <Text as="p">
                    {' '}
                    Acepto haber leído, comprendido y autorizado plenamente los{' '}
                    <a
                      href="https://mxlimited.mitiendanube.com/terminos-y-condiciones-vendedores/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Términos y condiciones del catálogo
                    </a>
                    .
                  </Text>
                </Box>
              </Box>
            </Card.Body>
          </Card>
          <Box display="flex" justifyContent="flex-end" gap="4" marginTop="6">
            <Button onClick={handleGoToDashboard}>CANCELAR</Button>
            {loading ? (
              <Button appearance="primary">
                <Spinner size="small" color="neutral-background" />
              </Button>
            ) : (
              <Button onClick={handleForm} type="submit" appearance="primary">
                ENVIAR
              </Button>
            )}
          </Box>
          <Box
            display={showMessageVinculateAccount ? 'flex' : 'none'}
            justifyContent="center"
            gap="10"
            marginTop="2"
            marginBottom="6"
          >
            <Text as="p" fontSize="highlight">
              <p>Vinculando cuenta, por favor aguarde unos instantes...</p>
            </Text>
          </Box>
        </>
      ) : (
        <>
          {!isVerifing && isVinculate && !isApproved && !isRejected ? (
            <>
              <Page.Header alignItems="center" title="" />
              <Page.Body>
                <Layout columns="1">
                  <Layout.Section>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="4"
                      padding="4"
                    >
                      <Title textAlign="center" as="h3">
                        Estado: esperando aprobación de cuenta
                      </Title>
                      <Card>
                        <Card.Body>
                          <Title fontSize="6">
                            ¡Estás a un paso de ser parte de Mexico Limited!
                          </Title>
                          <Text as="p">
                            Estamos revisando tu información para completar el
                            proceso de aprobación, el cual puede tomar hasta 78
                            horas. Mientras validamos tus datos, podríamos
                            necesitar más información para asegurarnos de que tu
                            negocio cumpla con los criterios establecidos. Si es
                            así, te contactaremos a través de los medios que nos
                            proporcionaste.
                          </Text>
                          <Text as="p">
                            ¿Listo para unirte a una comunidad única de
                            emprendedores? ¡Tu oportunidad está más cerca que
                            nunca! Mantente atento a nuestras actualizaciones.
                          </Text>
                        </Card.Body>
                      </Card>
                    </Box>
                  </Layout.Section>
                </Layout>
              </Page.Body>
            </>
          ) : (
            <>
              {!isVerifing && isVinculate && isApproved ? (
                <>
                  <Page.Header alignItems="center" title="" />
                  <Page.Body>
                    <Layout columns="1">
                      <Layout.Section>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap="4"
                          padding="4"
                        >
                          <Title textAlign="center" as="h3">
                            Estado: Cuenta aprobada
                          </Title>
                          <Card>
                            <Card.Body>
                              <Title fontSize="6">
                                ¡Felicidades, ahora eres parte de Mexico
                                Limited!
                              </Title>
                              <Text as="p">
                                Tu negocio ha sido aprobado y ahora formas parte
                                de la nuestra comunidad de empresas que está
                                impulsando el comercio mexicano. Estamos
                                emocionados de acompañarte en este camino de
                                crecimiento.
                              </Text>
                              <Text as="p">
                                Como miembro, tendrás acceso prioritario a un
                                catálogo de servicios profesionales diseñados
                                específicamente para PyMEs, con expertos en
                                áreas clave como diseño, asesoría legal,
                                contabilidad, marketing y ventas. Estos recursos
                                te ayudarán a fortalecer tu negocio y hacerlo
                                más competitivo, de manera estratégica y
                                efectiva.
                              </Text>
                              <Text as="p">
                                Además, serás parte de una red que promueve el
                                networking estratégico, acceso a eventos
                                exclusivos y la posibilidad de conectar con
                                aliados clave para tu crecimiento.
                              </Text>
                              <Text as="p">
                                Este es solo el comienzo de un camino lleno de
                                oportunidades. ¡Hagamos que tu negocio alcance
                                nuevos niveles juntos!.
                              </Text>
                            </Card.Body>
                          </Card>
                          {/* 
                          <Button
                            appearance="primary"
                            onClick={handleGoToConfig}
                          >
                            <Icon source={<CogIcon />} color="currentColor" />
                            IR A CONFIGURACIÓN
                          </Button>
                          */}
                        </Box>
                      </Layout.Section>
                    </Layout>
                  </Page.Body>
                </>
              ) : (
                <>
                  {!isVerifing && isVinculate && isRejected ? (
                    <>
                      <Page.Header alignItems="center" title="" />
                      <Page.Body>
                        <Layout columns="1">
                          <Layout.Section>
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="4"
                              padding="4"
                            >
                              <Title textAlign="center" as="h3">
                                Estado: Cuenta Rechazada
                              </Title>
                              <Card>
                                <Card.Body>
                                  <Title fontSize="6">
                                    ¡Agradecemos tu interés en formar parte de
                                    Mexico Limited!
                                  </Title>
                                  <Text as="p">
                                    Lamentamos informarte que, en esta ocasión,
                                    tu tienda no ha sido aprobada para formar
                                    parte de Mexico Limited. Te invitamos a
                                    revisar los detalles de tu aplicación y
                                    realizar las mejoras necesarias. Si tienes
                                    alguna duda sobre los criterios de
                                    aprobación o necesitas orientación para
                                    mejorar tu perfil, no dudes en ponerte en
                                    contacto con nosotros. Estamos aquí para
                                    ayudarte a alcanzar tu máximo potencial.
                                  </Text>
                                  <Text as="p">
                                    Estaremos encantados de recibir tu solicitud
                                    nuevamente en el futuro. Mientras tanto,
                                    mantente atento a las futuras oportunidades
                                    de crecimiento y recursos que tenemos para
                                    seguir apoyando el desarrollo de tu negocio.
                                    ¡Gracias por tu interés en ser parte de
                                    nuestra comunidad!.
                                  </Text>
                                </Card.Body>
                              </Card>
                            </Box>
                          </Layout.Section>
                        </Layout>
                      </Page.Body>
                    </>
                  ) : (
                    <>
                      {isUninstalled ? (
                        <>
                          <Page.Header alignItems="center" title="" />
                          <Page.Body>
                            <Layout columns="1">
                              <Layout.Section>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap="4"
                                  padding="4"
                                >
                                  <Title textAlign="center" as="h3">
                                    Estado: Cuenta Desinstalada
                                  </Title>
                                  <Card>
                                    <Card.Body>
                                      <Title fontSize="6">
                                        ¡Lamentamos verte partir!
                                      </Title>
                                      <Text as="p">
                                        Lamentamos saber que has decidido
                                        desinstalar nuestra aplicación. Ha sido
                                        un honor ser parte de tu camino
                                        emprendedor y ayudarte a impulsar tu
                                        negocio. Si hay algo en lo que podamos
                                        mejorar o apoyarte, no dudes en
                                        escribirnos a{' '}
                                        <strong>
                                          contacto@mexicolimited.com
                                        </strong>
                                        . Tu retroalimentación es valiosa para
                                        nosotros, y siempre estaremos aquí para
                                        ayudarte a superar los retos de tu
                                        negocio.
                                      </Text>
                                      <Text as="p">
                                        Esperamos verte de regreso pronto y
                                        seguir colaborando en tu éxito. ¡Gracias
                                        por confiar en nosotros!
                                      </Text>
                                    </Card.Body>
                                  </Card>
                                </Box>
                              </Layout.Section>
                            </Layout>
                          </Page.Body>
                        </>
                      ) : (
                        <>
                          <Page.Header
                            alignItems="center"
                            title="Formulario de alta"
                          />
                          <Box display="flex" gap="4" justifyContent="center">
                            <Button appearance="primary">
                              <Icon source={<CogIcon />} color="currentColor" />
                              ERROR
                            </Button>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </Page>
  );
};

export default PageDashboard;
